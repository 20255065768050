import styled from 'styled-components';
import { Link as LinkR } from 'react-router-dom';
import { Link as LinkS } from 'react-scroll';


export const Nav = styled.nav`
    background: ${({scrollNav}) => (scrollNav ? '#fafafa' : '#fafafa')};
    height: 80px;
    margin-top: -80px;
    display: flex;
    justify-content: center;
    align-item: center; 
    font-size: 1rem;
    position: sticky;
    top: 0;
    z-index: 10;

    @media screen and (max-width: 960px){
        transition: 0.8s all ease;
    }
`;

export const NavbarContainer = styled.div`
display: flex;
justify-content: space-between;
height: 80px;
z-index: 1;
width: 100%;
padding: 0 24px;
max-width: 1100px;
`;

export const NavLogo = styled(LinkR)`
color: #52A9F5;
justify-self: flex-start;
cursor: pointer;
font-size: 1.5rem;
display: flex;
align-items: center;
margin-left: 24px;
font-weight: bold;
text-decoration: none;
`;

export const MobileIcon = styled.div`
display: none;

@media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #fff;
}
`;

export const NavMenu = styled.ul`
display: flex;
align-items: center;
list-style: none;
text-align: center;
margin-rigth: -22px;


@media screen and (max-width: 768px){
    display: none;
}
`;

export const NavItem = styled.li`
height: 80px;
`;

export const NavLinks = styled(LinkS)`
    color: #52A9F5; 
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    font-weight: 600;
    &.active {
        border-bottom: 3px solid #fff;
    }
`;

export const NavBtn = styled.nav`
display: flex;
align-items: center;
font-weight: 600;
color: #52A9F5;

@media screen and (max-width: 768px){
    display: none;
}
`;

export const NavBtnLink = styled(LinkR)`
border-radius: 50px;
// background: ${({scrollNav}) => (scrollNav ? '#fff' : 'transparent')};
background: #fff;
whiste-space: nowrap;
padding: 10px 22px;
color: #0456B0;
font-size: 16px;
outline: none;
border: none;
cursor: pointer;
transition: all 0.2s ease-in-out;
text-decoration: none;

&:hover{
    transition: all 0.2s ease-in-out;
    background: #F6F6F6;
    color: #0456B0;
}
`;
export const ImgWrap = styled.div`
max-width: 400px;
height: 100%;
align-items: center;
// margin-right: 10px;
`;
export const Img = styled.img`

width: 130px;
// heig: 30px;
padding-right: 0px;
display: block;
margin-left: 0px;
margin-right: 0px;
// align-items: center;

@media screen and (max-width: 768px){
    width: 20%;
    align-items: center;
}

@media screen and (max-width: 480px){
    width: 30%;
}

`;
