import ImageOne from '../../Images/systematic.svg'
import ImageTwo from '../../Images/measure.svg'
import ImageThree from '../../Images/achieve.svg'


export const hero1 = {
    id: 'clients',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    imgStart: true,
    img1: ImageOne,
    img2: ImageTwo,
    img3: ImageThree,
    alt: 'data',
    dark: false,
    primary: false,
    darkText: true
};
