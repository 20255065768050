import React from 'react'
import {
    SidebarContainer,
    Icon,
    CloseIcon,
    SidebarWrapper,
    SidebarLink,
    SidebarMenu,
    SideBtnWrap,
    SidebarRoute
} from './SidebarElements';

const Sidebar = ({ isOpen, toggle }) => {
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon/>
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink to='company' onClick={toggle}>
                        Company
                    </SidebarLink>
                    <SidebarLink to='solutions' onClick={toggle}>
                       Solutions
                    </SidebarLink>
                    <SidebarLink to='services' onClick={toggle}>
                     Services
                    </SidebarLink>
                    <SidebarLink to='clients' onClick={toggle}>
                     Clients
                    </SidebarLink>
                    <SidebarLink to='contact' onClick={toggle}>
                        Contact
                    </SidebarLink>
                    {/* <SidebarLink to='signup' onClick={toggle}>
                        Sign Up
                    </SidebarLink> */}
                </SidebarMenu>
                <SideBtnWrap>
                    <SidebarRoute to= '/signin'>
                        Sign In
                    </SidebarRoute>
                </SideBtnWrap>
            </SidebarWrapper>
        </SidebarContainer>
    )
}

export default Sidebar
