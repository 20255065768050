import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// 1. Siapan pakai, 2.Tanpa investasi, 3.Free customisasi, 4. Pelatihan (optional). 5. Ada biaya sewa