import styled from 'styled-components';
import {MdKeyboardArrowRight, MdArrowForward} from 'react-icons/md';



export const HeroContainer = styled.div`
    background: #fafafa;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    height: 800px;
    position: relative;
    z-index: 1; 

 //darker video//

    :before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        // background-image: url(../../Images/bg.svg);
        // background: rgb(82,169,245);
        // background: linear-gradient(90deg, rgba(82,169,245,1) 0%, rgba(82,169,245,1) 100%);
        z-index: 2;
    }
`;

export const HeroBg = styled.div`
position: absolute;
top: 0;
right: 0;
bottom: 0;
left: 0;
width: 100%;
height: 100%;
overflow: hidden;
`;

export const VideoBg = styled.video`
width: 100%;
height: 100%;
-o-object-fit: cover;
object-fit: cover;
background: #232a34;
`;

export const HeroContent = styled.div`
z-index: 3;
max-width: 1200px;
position: absolute;
padding: 8px 24px;
display: flex;
flex-direction: column;
align-items: center;
`;

export const HeroH1 = styled.h1`
color: #22292f;
font-size: 120px;
text-align: center;
// text-shadow: 2px 3px 8px #000;

@media screen and (max-width: 768px){
    font-size: 120px;
}

@media screen and (max-width: 480px){
    font-size: 44px;
}
`;

export const HeroP = styled.p`
margin-top: 0px;
color: #22292f;
font-size: 28px;
font-weight: 600;
text-align: center;
max-width: 600px;
margin-bottom:20px;

@media screen and (max-width: 768px){
    font-size: 28px;
    margin-bottom:20px;
}

@media screen and (max-width: 480px){
    font-size: 18px;
    margin-bottom:0px;
    // margin-bottom:10px;
}
`;

export const HeroP2 = styled.p`
margin-top: -10px;
color: #22292f;
font-size: 28px;
font-weight: 600;
text-align: center;
max-width: 600px;
margin-bottom:20px;

@media screen and (max-width: 768px){
    font-size: 28px;
    margin-bottom:20px;
}

@media screen and (max-width: 480px){
    font-size: 18px;
    margin-bottom:0px;
    // margin-bottom:10px;
}
`;

export const HeroBtnWrapper = styled.div`
 margin-top: 32px;
 display: flex;
 flex-direction: column;
 align-items: center;
 font-weight: 500;
`;

export const ArrowForward = styled(MdArrowForward)`
margin-left: 8px;
font-size: 20px;
`;

export const ArrowRight = styled(MdKeyboardArrowRight)`
margin-left: 8px;
font-size: 20px;
`;


export const Column1  = styled.div`
// margin-bottom: 15px;
padding: 0 15px;
grid-area: col1;
`;

export const Column2 = styled.div`
// margin-bottom: 15px;
padding: 0 15px;
grid-area: col2;
`;

export const Column3 = styled.div`
margin-bottom: 15px;
padding: 0 15px;
grid-area: col3;
`;

export const InfoRow = styled.div`
display: grid;
grid-auto-columns: minmax(auto, 1fr);
align-items: center;
grid-template-areas: 'col1 col2 col3' ;
grid-gap: 0px;

@media screen and (max-width: 768px){
    grid-template-areas: 'col1 col1 col2 col2' 'col3 col3 ' ;
    display: grid;
}
@media screen and (max-width: 460px){
    grid-template-areas: 'col1 col1 ' 'col2 col2' 'col3 col3 ' ;
    // flex: 100%;
    // max-width: 100%;
}
`;

export const ImgWrap = styled.div`
max-width: 555px;
// height: 10%;
margin: 0 20px 0px 20px;

@media screen and (max-width: 768px){
    margin: 0 00px 0px 0px;

}

@media screen and (max-width: 480px){
    margin: 0px 0px 0px 100px;
   
}
`;

export const Img = styled.img`
// margin: 0 20px 10px 30px;
width: 1366px;
height: 768px;
// padding-right: 0;
// display:block;

@media screen and (max-width: 768px){
    margin: 0 00px 10px 50px;
    width: 60%;
}

@media screen and (max-width: 480px){
    margin: 0 0px 0px 0px;
    width: 50%;
    // max-width: 20%;
}

`;
export const Img1 = styled.img`
margin: 0 20px 10px 30px;
width: 100px;
height: 90px;
padding-right: 0;
display:block;

@media screen and (max-width: 768px){
    margin: 0 00px 10px 50px;
    width: 60%;
}

@media screen and (max-width: 480px){
    margin: 0 0px 0px 0px;
    width: 50%;
    // max-width: 20%;
}

`;

export const Img2 = styled.img`
margin: 0 20px 0px 25px;
width: 120px;
height: 110px;
padding-right: 0;
display:block;

@media screen and (max-width: 768px){
    margin: 0 10px 00px 40px;
    width: 60%;
}

@media screen and (max-width: 480px){
    margin: 0 0px 0px 0px;
    width: 50%;
    // max-width: 20%;
}

`;

export const Img3 = styled.img`
margin: 0 20px 0px 30px;
width: 120px;
height: 110px;
padding-right: 0;
display:block;

@media screen and (max-width: 768px){
    margin: 0 00px 10px 50px;
    width: 60%;
}

@media screen and (max-width: 480px){
    margin: 0 0px 0px 0px;
    width: 50%;
}

`;

