import styled from 'styled-components';

export const InfoContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
padding: 0 30px;
position: relative;
z-index: 1; 
color: #fff;
background: ${({lightBg}) => (lightBg ? '#f9f9f9' : '#0065D5')};

@media screen and (max-width: 768px){
    padding: 100px 0;
}

`;

export const InfoWrapper = styled.div`
    display: flex;
    // justify-content: center;
    position: relative;
    align-items: center;
    // display: grid;
    text-align: center;
    z-index: 3;
    height: 400px;
    width: 100%;
    max-width: 1200px;
    // margin-right: auto;
    // margin-left: auto;
    padding: 8px 24px;
    justify-content: center;
    @media screen and (max-width: 768px){
        height: 360px;
    }
    @media screen and (max-width: 480px){
        height: 360px;
    }
`;

export const InfoRow = styled.div`
display: grid;
grid-auto-columns: minmax(auto, 1fr);
align-items: center;
grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};

@media screen and (max-width: 768px){
    grid-template-areas: ${({imgStart}) => (imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`)};
}
`;

export const Column1  = styled.div`
margin-bottom: 15px;
padding: 0 15px;
grid-area: col1;
`;

export const Column2 = styled.div`
margin-bottom: 15px;
padding: 0 15px;
grid-area: col2;
`;

export const TextWrapper = styled.div`
max-width: 1000px;
padding-top:0;
padding-bottom: 60px;

`;

export const TopLine = styled.p`
    color: #0065D5;
    font-size: 20px;
    // line-height: 16px;
    // font-weight: 700;
    // letter-spacing: 1.4px;
    text-transform: uppercase;
    // margin-bottom: 16px;
`;

export const Heading = styled.h1`
    color:  ${({lightText}) => (lightText ? '#f7f8fa' : '#010606')};
    font-size: 40px;
    line-height: 1.3;
    font-weight: 600;
    margin-bottom: 24px;
    width: auto;
    // max-width: 1000px;
    direction: rtl;
    @media screen and (max-width: 480px){
        font-size:32px;
    }
`;

export const Subtitle = styled.p`
  max-width: 1000px;
  margin-bottom: 35px;
  font-size: 20px;
//   line-height: 34px;
  font-family: "Poppins", sans-serif;
  line-height: 150%;
  color: ${({darkText}) => (darkText ? '#010606' : '#fff')};
`;


export const BtnWrap = styled.div`
    justify-content: flex-start;
    width: 100%;
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 500;
`;

export const ImgWrap = styled.div`
max-width: 555px;
height: 100%;
margin-right: 10px;
`;

export const Img = styled.img`
width: 150px;
padding-right: 0px;
display: block;
margin-left: 430px;

// margin-right: 200px;

@media screen and (max-width: 768px){
    width: 40%;
    align-items: center;
    margin-right: auto;
    margin-left: 240px;
}

@media screen and (max-width: 480px){
    width: 30%;
    margin-right: auto;
    margin-left: auto;
}
`;



