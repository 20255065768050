import React from 'react'
import{
    Container,
    FormWrap,
    Icon,
    FormContent,
    Form,
    FormH1,
    FormLabel,
    FormInput,
    FormButton,
    Text
} from './SingInElements'



const SingIn = () => {
    return (
        <>
          <Container id="singin">
              <FormWrap>
                  <Icon to='/'>EFECTOS</Icon>
                  <FormContent>
                      <Form action='#'>
                          <FormH1>Sign in to your account</FormH1>
                            <FormLabel htmlFor='for'>email</FormLabel>
                            <FormInput type='email' required/>
                            <FormLabel htmlFor='for'>Passowrd</FormLabel>
                            <FormInput type='password' required/>
                            <FormButton type='submit'>Continue</FormButton>
                            <Text>Forgot password?</Text>
                      </Form>
                  </FormContent>
              </FormWrap>
        </Container>  
        </>
    )
}

export default SingIn


