import styled from 'styled-components';

export const InfoContainer = styled.div`
color: #fff;
background: ${({lightBg}) => (lightBg ? '#f9f9f9' : '#0065D5')};

@media screen and (max-width: 768px){
    padding: 100px 0;
}

`;

export const InfoWrapper = styled.div`
    display: grid;
    z-index:1;
    height: 640px;
    width: 100%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 24px;
    justify-content: center;
`;

export const InfoRow = styled.div`
display: grid;
grid-auto-columns: minmax(auto, 1fr);
align-items: center;
grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};

@media screen and (max-width: 768px){
    grid-template-areas: ${({imgStart}) => (imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`)};
}
`;

export const Column1  = styled.div`
margin-bottom: 15px;
padding: 0 15px;
grid-area: col1;
`;

export const Column2 = styled.div`
margin-bottom: 15px;
padding: 0 15px;
grid-area: col2;
`;

export const TextWrapper = styled.div`
max-width: 540px;
padding-top:0;
padding-bottom: 60px;
`;

export const TopLine = styled.p`
    color: #22292f;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;
`;

export const Heading = styled.h1`
    color:  ${({lightText}) => (lightText ? '#f7f8fa' : '#22292f')};
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    margin-bottom: 24px;

    @media screen and (max-width: 480px){
        font-size:32px;
    }
`;

export const Subtitle = styled.p`
  max-width: 440px;
  margin-bottom: 35px;
//   letter-spacing: 3px;
  font-size: 18px;
  font-weight: 400;
  line-height: 34px;
  color: ${({darkText}) => (darkText ? '#010606' : '#fff')};
`;


export const ImgWrap = styled.div`
max-width: 555px;
height: 100%;
margin-right: 10px;
`;

export const Img = styled.img`
margin: 0 0 10px 0;
width: 100%;
padding-right: 0;
`;


export const BtnWrap = styled.div`
    justify-content: flex-start;
    width: 25%;
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 500;
`;

