import styled from 'styled-components';

export const ServicesContainer =styled.div`
height: 600px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background: #52A9F5;

@media screen and (max-width: 768px){
    height: 1400px;
}

@media screen and (max-width: 480px){
    height: 1400px;
    
}
`;

export const ServicesWrapper =styled.div`
    max-width: 1000px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    grid-gap: 46px;
    padding: 0 30px;

    @media screen and (max-width: 1000px){
        grid-template-columns: 1fr 1fr 1fr;
        
    }
    
    @media screen and (max-width: 768px){
        grid-template-columns: 1fr ;
        padding: 0 20px;
    }

    @media screen and (max-width: 480px){
        grid-template-columns: 1fr ;
        
        // padding: 0 20px;
    }

`;

export const ServicesCard = styled.div`
 background: #fff;
 display: flex;
 flex-direction: column;
 justify-content: flex-start;
 align-items: center;
 border-radius: 20px;
 max-height: 360px;
 
 padding: 30px;
 box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
 transition: all 0.2s ease-in-out;

 @media screen and (max-width: 768px){
    max-height: 360px;
    margin-right: 180px;
    margin-left: 180px;
}

 @media screen and (max-width: 480px){
    max-height: 360px;
   margin-right: 20px;
   margin-left: 20px;
}

 &:hover{
     transform: scale(1.02);
     transition: all 0.2s ease-in-out;
     cursor: pointer;
 }
`;

export const ServicesIcon = styled.img`
height: 120px;
width: 120px;
margin-bottom: 5px;
align-items: left;
`;

export const ServicesH1 = styled.h1`
font-size: 1.7rem;
color: #fff;
margin-bottom: 64px;
text-align: center;
font-weight: 600;

@media screen and (max-width: 480px){
    font-size: 16px;
    text-align: center;
    margin-left: 30px;
    margin-right: 30px;
    line-height: 24px;
}
`;

export const ServicesH2 = styled.h2`
font-size: 1rem;
color: #1c1c1c;
margin-bottom: 10px;
text-align: center;
`;

export const ServicesP = styled.p`
font-size: 1rem;
text-align: center;

`;

export const BtnWrap = styled.div`
    dipslay: flex;
    justify-content: flex-start;
    width: 40%;
`;

export const ServicesBtn = styled.div`
 margin-top: 10px;
 display: flex;
 flex-direction: column;
 align-items: center;
 font-weight: 500;

`;
