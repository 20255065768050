import ImageOne from '../../Images/effectos.svg'


export const how = {
    id: 'solutions',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    // topLine: 'GAP Track',
    headline: 'What We Do?',
    description: 'EFFECTOS aims to improve project management in a more systematic and measurable manner with better work standards, focus on prioritizing traceability, managing risk or uncertainty, and setting new targets.',
    buttonLabel: 'See More',
    imgStart: false,
    img: ImageOne,
    alt: 'program',
    dark: false,
    primary: false,
    darkText: true
};

