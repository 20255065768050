import React, { useEffect } from 'react'
import { Button } from '../ButtonElements';
import Aos from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import {
    InfoContainer,
    InfoWrapper,
    InfoRow,
    Column1,
    Column2,
    TextWrapper,
    TopLine,
    Heading,
    Subtitle,
    ImgWrap,
    Img,
    BtnWrap
} from './wedoElements'

const Wedo = ({
    lightBg, 
    id, 
    imgStart, 
    topLine, 
    lightText,
    headline, 
    darkText, 
    description, 
    buttonLabel, 
    img, 
    alt,
    primary,
    dark,
    dark2
    }) => {
        useEffect(() => {
            Aos.init({duration: 2000 });
    
        }, []);
    return (
     
        <>
          <InfoContainer lightBg={lightBg} id={id}>
            <InfoWrapper>
                <InfoRow imgStart={imgStart}>
                    <Column1 data-aos="fade-down" data-aos-duration="2000" data-aos-once >
                        <TextWrapper>
                            <TopLine> {topLine} </TopLine>
                            <Heading lightText={lightText}> {headline}
                            </Heading>
                            <Subtitle darkText={darkText}>{description}</Subtitle>
                            <BtnWrap>
                                <Button to='solutions1' 
                                smooth={true}
                                duration={500}
                                spy={true}
                                exact="true"
                                offset={-80}
                                primary={primary ? 1 : 0}
                                dark={dark ? 1 : 0}
                                dark2={dark2 ? 1 : 0}  
                                >{buttonLabel}</Button>
                            </BtnWrap>
                        </TextWrapper>
                    </Column1>
                    <Column2 data-aos="fade-up" data-aos-duration="2000" data-aos-delay="300"  data-aos-once>
                        <ImgWrap>
                        <Img src={img} alt={alt}/>
                        </ImgWrap>
                    </Column2>
                </InfoRow>
            </InfoWrapper>    
          </InfoContainer>  
        </>
    );
};

export default Wedo
