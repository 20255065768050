import React, {useState} from 'react';
import Footer from '../Components/Footer';
import HeroSection from '../Components/HeroSection';
import InfoSection from '../Components/InfoSection';
import { homeObjOne } from '../Components/InfoSection/Data';
import { solution1, solution2, solution3 } from '../Components/solutions/Data';
import { gambar1 } from '../Components/clients/Data';
import { hero1 } from '../Components/HeroSection/Data';
import {how} from '../Components/wedo/Data';
import Navbar from '../Components/Navbar';
import Services from '../Components/Services';
import Sidebar from '../Components/Sidebar';
import Solutions from '../Components/solutions';
import Wedo from '../Components/wedo';
import Clients from '../Components/clients';
import Get from '../Components/Get';

const Home = () => {
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    };
    return (
        <>
            <Sidebar isOpen={isOpen} toggle={toggle}/>
            <Navbar toggle={toggle} />
            <HeroSection {...hero1}/>
            {/* <InfoSection {...homeObjOne}/> */}
            <Get />
            
            <Wedo {...how} />
            <Solutions {...solution1}/>
            <Solutions {...solution2}/>
            <Solutions {...solution3}/>
            <Services />
            <Clients {...gambar1} />
            
            {/* <InfoSection {...homeObjsix}/> */}
            <Footer/>
        </>
    );
};

export default Home
