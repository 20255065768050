import styled from 'styled-components';

export const ServicesContainer =styled.div`
height: 400px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background: #f9f9f9;

@media screen and (max-width: 1000px){
    height: 1000px;
}

@media screen and (max-width: 768px){
    height: 2100px;
}

@media screen and (max-width: 480px){
    height: 2000px;
    
}
`;

export const ServicesWrapper =styled.div`
    max-width: 1100px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    // align-items: center;
    grid-gap: 40px;
    // padding: 0 30px;

    @media screen and (max-width: 1000px){
        grid-template-columns: 1fr 1fr 1fr;
        
    }
    
    @media screen and (max-width: 768px){
        grid-template-columns: 1fr ;
        padding: 0 20px;
    }

    @media screen and (max-width: 480px){
        grid-template-columns: 1fr ;
        
        // padding: 0 20px;
    }

`;

export const ServicesCard = styled.div`
 background: #f9f9f9;
 display: flex;
 flex-direction: column;
 justify-content: flex-start;
 align-items: center;
 border-radius: 20px;
 max-height: 1060px;
//  max-width: 1000px,
 padding: 20px;
 box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
 transition: all 0.2s ease-in-out;

 @media screen and (max-width: 768px){
    max-height: 320px;
    margin-right: 180px;
    margin-left: 180px;
}

 @media screen and (max-width: 480px){
    max-height: 300px;
   margin-right: 20px;
   margin-left: 20px;
}

 &:hover{
     transform: scale(2.02);
     transition: all 0.4s ease-in-out;
     cursor: pointer;
     color: #b0b0b0,
 }
`;

export const ServicesIcon = styled.img`
height: 120px;
width: 120px;
margin-bottom: 5px;
align-items: left;
`;

export const ServicesH1 = styled.h1`
font-size: 2rem;
color: #22292f;
margin-bottom: 20px;
text-align: center;
font-weight: 600;

@media screen and (max-width: 480px){
    font-size: 20px;
    text-align: center;
    margin-left: 30px;
    margin-right: 30px;
    line-height: 24px;
}
`;

export const Servicesp = styled.li`
max-width: 1000px;
  margin-bottom: 30px;
  margin-top: 10px;
  font-size: 20px;
  position:relative; 
//   text-align: left;
  line-height: 50px;
  font-family: "Poppins", sans-serif;
//   line-height: 150%;
//   font-weight: 00;

  @media screen and (max-width: 480px){
    font-size: 16px;
    text-align: center;
    margin-left: 30px;
    margin-right: 30px;
    line-height: 24px;
}
`;
export const ServicesH2 = styled.p`
max-width: 1000px;
  margin-bottom: 0px;
  font-size: 20px;
  text-align: center;
  line-height: 50px;
  font-family: "Poppins", sans-serif;
  line-height: 150%;
  font-weight: 500;
  color: #22292f;

  @media screen and (max-width: 480px){
    font-size: 16px;
    text-align: center;
    margin-left: 30px;
    margin-right: 30px;
    line-height: 24px;
}
`;

export const ServicesP = styled.p`
font-size: 1rem;
text-align: left;
margin-top: 10px;
color: #22292f;

`;

export const BtnWrap = styled.div`
    dipslay: flex;
    justify-content: flex-start;
    width: 40%;
`;

export const ServicesBtn = styled.div`
 margin-top: 10px;
 display: flex;
 flex-direction: column;
 align-items: center;
 font-weight: 500;

`;

export const div = styled.div`
padding-bottom:5px;

`;
