import styled from 'styled-components';
import {Link} from 'react-router-dom';



export const FooterContainer = styled.footer`
 background-color: #52A9F5;
`;
export const FooterWrap = styled.div`
padding: 48px 34px;
display: flex;
flex-direction: Column;
justify-content: center;
align-item: center;
max-width: 1100px;
margin: 0 auto;
`;

export const FooterLinksContainer = styled.div`
    display: flex;
    justify-content: center;

    @media screen and (max-width: 820px){
        padding-top: 32px;
    }
    @media screen and (max-width: 420px){
        padding-top: 32px;
        justify-content: center;
        flex-direction: column;
        
    }
`;

export const FooterLinksWrapper = styled.div`
display: flex;

@media screen and (max-width: 820px){
    flex-direction: column;
}
@media screen and (max-width: 420px){
    flex-direction: column;
}
`;

export const FooterLinkItems = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
margin: 16px;
text-align: left;
width: 200px;
box-sizing: border-box;
color: #fff;

@media screen and (max-width: 768px){
    margin: 0;
    padding: 5px;
    width: 100%;
    flex-direction: column;
}

@media screen and (max-width: 420px){
    margin: 0;
    padding: 10px;
    width: 150%;
    flex-direction: column;
}
`;

export const FooterLinkTitle = styled.h1`
font-size: 16px;
margin-bottom: 16px;
`;

export const Footerp = styled.p`
color: #fff;
text-decoration: none;
margin-bottom: 0.5rem;
font-size: 14px;

@media screen and (max-width: 820px){
    font-size: 14px;
}
@media screen and (max-width: 420px){
    font-size: 14px;
}
`;

export const FooterLink = styled(Link)`
color: #fff;
text-decoration: none;
margin-bottom: 0.5rem;
font-size: 14px;

@media screen and (max-width: 820px){
    font-size: 12px;
}
@media screen and (max-width: 420px){
    font-size: 0.6rem;
}

&:hover{
    color: #01bf71;
    transition: 0.3 ease-out;
}
`;


export const FooterLinka = styled.a`
color: #fff;
text-decoration: none;
margin-bottom: 0.5rem;
margin-left: 5px;
margin-top: 7px;
font-size: 14px;

@media screen and (max-width: 820px){
    font-size: 12px;
}
@media screen and (max-width: 420px){
    font-size: 12px;
}

&:hover{
    color: #01bf71;
    transition: 0.3 ease-out;
}
`;

export const SocialMedia = styled.section`
max-width: 1000px;
width: 100%;
`;

export const SocialMediaWrap = styled.div`
display: flex;
// justify-content: space-between;
align-items: center;
max-width: 1000px;
// margin: 0px 0px 0 400px;
flex-direction: column;

@media screen and (max-width: 820px ){
    flex-direction: column;
    margin: 50px 0px 0 0px;
}

@media screen and (max-width: 480px ){
    flex-direction: column;
    margin: 50px 0px 0 0px;
}

`;

export const SocialLogo = styled(Link)`
color: #fff;
justify-self: start;
cursor: pointer;
text-decoration: none;
font-size: 1.5rem;
display: flex;
align-item: left;
margin-bottom: 16px;
font-weight: bold;

`;

export const WebsiteRights = styled.small`
color: #fff;
margin-bottom: 16px;
// margin-right: 106px;
display:flex;
align-items: center;
text-align: center;

@media screen and (max-width: 768px){
    margin-right: 0px;
}

@media screen and (max-width: 480px){
margin-right: 0px;

`;

export const SocialIcons = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
width: 240px;
// flex-direction: column;
`;

export const SocialIconLink = styled.a`
color: #fff;
font-size: 24px;
`;


export const ImgWrap = styled.div`
max-width: 400px;
height: 100%;
align-items: center;
@media screen and (max-width: 420px){
    margin: 0;
    // padding: 10px;
    width: 100%;
}
`;
export const Img = styled.img`

width: 80px;
padding-right: 0px;
display: block;
// margin-top: 0
margin-left: 5px;
// margin-right: 200px;

@media screen and (max-width: 768px){
    width: 20%;
    // align-items: center;
    margin-right: auto;
}

@media screen and (max-width: 480px){
    width: 100%;
    margin: 0 0px 0 0px;
    
}

`;





