import ImageOne from '../../Images/lodaya1.svg'
import ImageTwo from '../../Images/lodaya2.svg'
import ImageThree from '../../Images/monitoring.svg'

export const solution1 = {
    id: 'solutions1',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    // topLine: 'GAP Track',
    headline: 'Systematic program',
    description: 'Ensure that project planning is carried out well, where all steps can be measured the level of success with the indicators that the EFFECTOS system has provided',
    // buttonLabel: 'See More',
    imgStart: true,
    img: ImageOne,
    alt: 'program',
    dark: false,
    primary: false,
    darkText: true
};

export const solution2 = {
    id: 'solutions2',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    // topLine: 'Our Solutions!',
    headline: 'Priority & optimization',
    description: 'Determine the priority points of a project, so the output data remains optimal and minimizes changes that will occur in a project',
    // buttonLabel: 'Learn More',
    imgStart: false,
    img: ImageTwo,
    alt: 'people',
    dark: false,
    primary: false,
    darkText: true
};

export const solution3 = {
    id: 'solutions3',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    // topLine: 'Solution 1',
    headline: 'Start Monitoring',
    description: 'Monitor the project based on the results of data that occur in the field using the available templates. To minimize the occurrence of data falsification.',
    // buttonLabel: 'See More',
    imgStart: true,
    img: ImageThree,
    alt: 'program',
    dark: false,
    primary: false,
    darkText: true
};