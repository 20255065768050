import React, {useState, useEffect, useRef } from 'react';
import Icon1 from '../../Images/ready.svg'
import Icon2 from '../../Images/finance.svg'
import Icon3 from '../../Images/free.svg'
// import Icon4 from '../../Images/trainning.svg'
// import Icon5 from '../../Images/subs.svg'
// import animationData from '../../Images/lotties/bisnis.json';
// import lottie from 'lottie-web';
import Aos from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import{
        ServicesContainer,
        ServicesH1,
        ServicesCard,
        ServicesH2,
        ServicesIcon,
        ServicesP,
        ServicesWrapper,
        div,
        Servicesp
} from './GetElements';

import { 
    ArrowForward,
    ArrowRight
} from '../HeroSection/HeroElements';


const Get = ({
    primary,
    dark
}) => {
    useEffect(() => {
        Aos.init({duration: 2000 });
        // lottie.loadAnimation(
        //     {
        //     container: container.current,
        //     renderer: 'svg',
        //     loop: true,
        //     autoplay: true,
        //     animationData: require('../../Images/lotties/bisnis.json')
        //   }

    }, []);
    const [hover, setHover] = useState(false)

    const onHover = () => {
        setHover(!hover)
    }
    // const container = useRef(null)
  
  
  

    return (
        <ServicesContainer id='get'>
            {/* <ServicesH1 data-aos="fade-right" data-aos-duration="4000" data-aos-once>What You’ll Get? </ServicesH1> */}
            <ServicesH1 data-aos="fade-right" data-aos-duration="4000" data-aos-once>To force business strategies to be executed effectively</ServicesH1>
            {/* <Servicesp data-aos="fade-right" data-aos-duration="4000" data-aos-once>We offers you a traceability system. Best practices monitoring. Results based approach. Proven methods</Servicesp> */}
            <ServicesWrapper>
                <ServicesCard data-aos="fade-up" data-aos-duration="2000" data-aos-delay="500"  data-aos-once>
                    <ServicesH2>SMART Planning</ServicesH2>
                    {/* <div className="container" ref={container}></div> */}
                    {/* <ServicesIcon src={Icon1}/> */}
                    <ServicesP>
                        <ol>
                            <li>
                                Specific
                            </li>
                            <li>
                                Measureable
                            </li>
                            <li>
                                Achievable
                            </li>
                            <li>
                                Relevan
                            </li>
                            <li>
                                Timebound
                            </li>
                        </ol>                
                    </ServicesP>
                 
          
                
                    
                </ServicesCard>
                <ServicesCard data-aos="fade-up" data-aos-duration="2000" data-aos-delay="700"  data-aos-once>
                     <ServicesH2>Collecting results regularly</ServicesH2>
                     {/* <div className="container" ref={container2}></div> */}
                    {/* <ServicesIcon src={Icon2}/> */}
                    <ServicesP>Always Updated Results</ServicesP>
                    
                </ServicesCard>
                <ServicesCard data-aos="fade-up" data-aos-duration="2000" data-aos-delay="900"  data-aos-once>
                    <ServicesH2>Fast & Cheap</ServicesH2>
                    {/* <div className="container" ref={container}></div> */}
                    {/* <ServicesIcon src={Icon3}/> */}
                    <ServicesP>
                        <ol>
                            <li>
                                 Fast customization
                            </li>
                            <li>
                                Without Investment
                            </li>
                            <li>
                                 Subscription system
                            </li>                   
                            <li>
                                Trainning (Optional)
                            </li>                   
                        </ol>                
                    </ServicesP>
                    
                </ServicesCard>
                {/* <ServicesCard data-aos="fade-up" data-aos-duration="2000" data-aos-delay="1100"  data-aos-once>
                    <ServicesH2>Trainning(Optional)</ServicesH2>
                    <ServicesIcon src={Icon4}/>
                    <ServicesP>increase the productivity of a system, by managing work standards properly.</ServicesP>
                   
                </ServicesCard>
                <ServicesCard data-aos="fade-up" data-aos-duration="2000" data-aos-delay="1300"  data-aos-once>
                    <ServicesH2>Subscription</ServicesH2>
                    <ServicesIcon src={Icon5}/>
                    <ServicesP>increase the productivity of a system, by managing work standards properly.</ServicesP>
                   
                </ServicesCard> */}
            </ServicesWrapper>
            
        </ServicesContainer>
    )
}

export default Get
