import React from 'react'
import {animateScroll as scroll} from 'react-scroll'
import {FaFacebook, FaInstagram, FaYoutube, FaLinkedin} from 'react-icons/fa'
import logo from '../../Images/EFFECTOS-Putih.png'

import {FooterContainer,
     FooterWrap, 
     FooterLinksContainer,
     FooterLinksWrapper, 
     FooterLinkItems, 
     FooterLinkTitle,
     FooterLink,
     SocialMedia, 
     Footerp,
     FooterLinka,
     SocialIconLink, 
     SocialIcons, 
     SocialLogo, 
     ImgWrap,
     Img,
     SocialMediaWrap, 
     WebsiteRights}
from './FooterElements'
const Footer = () => {

    const toggleHome= () => {
        scroll.scrollToTop();
    }
    

    return (
        <FooterContainer id='contact'>
            <FooterWrap>
                <FooterLinksContainer>           
                    {/* <FooterLinksWrapper>
                        <FooterLinkItems>
                            <FooterLinkTitle>About Us</FooterLinkTitle>
                                <FooterLink to='/' onClick={toggleHome}> How it works </FooterLink>
                                <FooterLink to='/'onClick={toggleHome}> Testimonials </FooterLink>
                                <FooterLink to='/'> Terms of Service </FooterLink>
                        </FooterLinkItems>
                    </FooterLinksWrapper> */}
                    <FooterLinksWrapper>
                        <FooterLinkItems>
                            <FooterLinkTitle>Address</FooterLinkTitle>
                                <Footerp >Komp.Griya Cigadung</Footerp>
                                <Footerp >Baru E.2, Cibeunying  </Footerp>
                                <Footerp >Kaler, Kota Bandung, </Footerp>
                                <Footerp >Indonesia</Footerp>
                                
                        </FooterLinkItems>
                    </FooterLinksWrapper>
                    <FooterLinksWrapper>
                        <FooterLinkItems>
                            <FooterLinkTitle>Contact Us</FooterLinkTitle>
                                {/* <FooterLink to='/signin'> Instagram </FooterLink> */}
                                <Footerp href="/" > effectos.services@gmail.com </Footerp>
                                {/* <FooterLink to='/signin'> Facebook </FooterLink> */}
                                <Footerp to='/'> +62 812-2224-2715 (Whatsapp) </Footerp>
                        </FooterLinkItems>
                    </FooterLinksWrapper> 
                    </FooterLinksContainer>             
                <SocialMedia>
                    <SocialMediaWrap>
                        <WebsiteRights>© {new Date().getFullYear()} All Rights Reserved by <FooterLinka href="https://arafa.asia/" target="_blank">arafa.asia</FooterLinka></WebsiteRights>
                          {/* <ImgWrap>
                                <Img src={logo}  alt="logo"/>
                             </ImgWrap>  */}
                        {/* <SocialIcons>
                            <SocialIconLink href="/" target="_blank"
                            arial-label="Instagram">
                                <FaInstagram/>
                            </SocialIconLink>
                            <SocialIconLink href="//www.youtube.com/channel/UCEjfRugnrVcn352N0z7WqBA" target="_blank"
                            arial-label="Youtube">
                                <FaYoutube/>
                            </SocialIconLink>
                            <SocialIconLink href="/" target="_blank"
                            arial-label="Facebook">
                                <FaFacebook/>
                            </SocialIconLink>
                            <SocialIconLink href="/" target="_blank"
                            arial-label="Linkedin">
                                <FaLinkedin/>
                            </SocialIconLink>
                        </SocialIcons> */}
                    </SocialMediaWrap>
                </SocialMedia>
            </FooterWrap>
        </FooterContainer>
      
    )
}

export default Footer
