import React from 'react'
import ScrollToTop from '../Components/ScrollToTop'
import SingIn from '../Components/Signin'

const SigninPage = () => {
    return (
        <>
            <ScrollToTop/>
            
            <SingIn />
        </>
    )
}

export default SigninPage
