import styled from 'styled-components';

export const InfoContainer = styled.div`

color: #fff;
background: ${({lightBg}) => (lightBg ? '#f9f9f9' : '#0065D5')};

@media screen and (max-width: 768px){
    padding: 100px 0;
}

`;

export const InfoWrapper = styled.div`
    display: grid;
    z-index:1;
    height: 540px;
    width: 100%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 10px 0px;
    justify-content: center;
    
    @media screen and (max-width: 768px){
        height: 340px;
    }
    
    @media screen and (max-width: 480px){
        height: 240px;
    }
`;

export const InfoRow = styled.div`
display: grid;
grid-auto-columns: minmax(auto, 1fr);
align-items: center;
grid-template-areas: 'col1 col2 col3' 'col4 col5 col6' '. col7 .'  ;
grid-gap: 0px;

@media screen and (max-width: 768px){
    grid-template-areas: 'col1 col1 col2 col2' 'col3 col3 col4 col4' 'col5 col5 col6 col6' 'col7 col7';
    display: grid;
}
`;

export const Column1  = styled.div`
// margin-bottom: 15px;
padding: 0 15px;
grid-area: col1;
`;

export const Column2 = styled.div`
// margin-bottom: 15px;
padding: 0 15px;
grid-area: col2;
`;

export const Column3 = styled.div`
margin-bottom: 15px;
padding: 0 15px;
grid-area: col3;
`;
export const Column4 = styled.div`
margin-bottom: 15px;
padding: 0 15px;
grid-area: col4;
`;
export const Column5 = styled.div`
margin-bottom: 15px;
padding: 0 15px;
grid-area: col5;
`;
export const Column6 = styled.div`
margin-bottom: 15px;
padding: 0 15px;
grid-area: col6;
`;
export const Column7 = styled.div`
margin-bottom: 15px;
padding: 0 15px;
grid-area: col7;
`;

export const TextWrapper = styled.div`
max-width: 540px;
padding-top:0;
padding-bottom: 60px;
`;

export const TopLine = styled.p`
    color: #0065D5;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;
`;

export const Heading = styled.h1`
    color:  ${({lightText}) => (lightText ? '#f7f8fa' : '#010606')};
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    margin-bottom: 24px;

    @media screen and (max-width: 480px){
        font-size:32px;
    }
`;

export const Subtitle = styled.p`
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  color: ${({darkText}) => (darkText ? '#010606' : '#fff')};
`;


export const ImgWrap = styled.div`
max-width: 555px;
// height: 10%;
margin: 0 20px 0px 20px;
`;

export const Img = styled.img`
margin: 0 20px 0px 20px;
width: 240px;

padding-right: 0;

@media screen and (max-width: 768px){
    margin: 0 00px 0px 0px;
    width: 100%;
    
}

@media screen and (max-width: 480px){
    margin: 0 0px 0px 0px;
    width: 150%;

    
}

`;


export const BtnWrap = styled.div`
    justify-content: flex-start;
    width: 25%;
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 500;
`;

export const ClientsH1 = styled.h1`
font-size: 1.7rem;
color: #303436;
padding-top: 50px;
margin-bottom: 0px;
text-align: center;
font-weight: 600;
@media screen and (max-width: 768px){
    font-size: 1.5rem;
    padding: 0px 10px;
    
}
@media screen and (max-width: 480px){
    font-size: 1rem;
}
`;

export const ClientsH2 = styled.h2`
font-size: 1rem;
color: #303436;
margin-bottom: 20px;
text-align: center;
padding: 0px 200px;
font-weight: 500;
@media screen and (max-width: 768px){
    font-size: 1rem;
    padding: 0px 20px;
    
}
@media screen and (max-width: 480px){
    font-size: 1rem;
    
}
`;