import styled from 'styled-components';
import {Link} from 'react-scroll';

export const Button = styled(Link)`
    border-radius: 40px;
    background: ${({primary}) => (primary ? '#fff' : '#0065D5')};
    white-space: nowrap;
    padding: ${({big}) => (big ? '14px 40px' : '12px 30px')};
    color: ${({dark}) => (dark ? '#0065D5' : '#fff')};
    font-size: ${({fontBig}) => (fontBig ? '20px' : '16px')};
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;

    &:hover{
        transition: all 0.2s ease-in-out;
        background: ${({primary}) => (primary ? '#D9D9D9' : '#0456B0')};
    }

    @media screen and (max-width: 768px){
        grid-template-columns: 1fr;
        // padding: 0 20px;
    }
    

`;