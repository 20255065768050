import React, {useState, useEffect} from 'react';
import {Button} from '../ButtonElements';
import Typewriter from "typewriter-effect";
import Aos from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import bg from '../../Images/bg1.svg';
import Ripples from 'react-ripples';

import { 
    HeroContainer, 
    HeroBg, 
    HeroContent,
    HeroBtnWrapper,
    HeroH1,
    HeroP,
    HeroP2,
    ArrowForward,
    ArrowRight,
    InfoRow,
    Column1,
    Column2,
    Column3,
    ImgWrap,
    Img,
    Img1,
    Img2,
    Img3,
} from './HeroElements';

const HeroSection = ({
    img1, 
    img2, 
    img3, 
    primary,
    dark,
    imgStart,
    alt
}) => {
    useEffect(() => {
        Aos.init({duration: 2000 });

    }, []);

    const [hover, setHover] = useState(false)

    const onHover = () => {
        setHover(!hover)
    }
    
        

    return (
        <HeroContainer id="home">
            <HeroBg>
                {/* <Img src={bg}/> */}
            </HeroBg>
            <HeroContent>
                <HeroH1  data-aos="fade-down" data-aos-duration="1000">
                    <Typewriter
                    onInit={(typewriter) => {
                        typewriter
                        .typeString("EFFECTOS")
                        .pauseFor(1000)
                        .deleteAll()
                        .typeString("EFFECTOS")
                        .start();                        
                        }}
                        />
                 {/* EFFECTOS */}
                </HeroH1>
                <HeroP data-aos="fade-down" data-aos-duration="2000" >
                Results-Oriented Monitoring System
                 </HeroP>   
                <InfoRow  imgStart={imgStart}>
                    <Column1 data-aos="fade-down" data-aos-duration="2000" data-aos-delay="500"> 
                     <ImgWrap >
                    
                        <Img1 src={img1} alt={alt}/>
                        
                        </ImgWrap>
                        <HeroP>
                              Systematic
                        </HeroP>
                    </Column1>
                    <Column2 data-aos="fade-up" data-aos-duration="2000" data-aos-delay="700">
                        <ImgWrap>
                        <Img2 src={img2} alt={alt}/>
                        </ImgWrap>
                        <HeroP2>
                              Measure
                        </HeroP2>
                    </Column2>
                    <Column3 data-aos="fade-down" data-aos-duration="2000" data-aos-delay="900">
                        <ImgWrap>
                        <Img3 src={img3} alt={alt}/>
                        </ImgWrap>
                        <HeroP>
                              Achieve
                        </HeroP>
                    </Column3>
                </InfoRow>
               
                <HeroBtnWrapper data-aos="fade-up" data-aos-duration="2000" data-aos-delay="1200">
                <Ripples color={'yellow'}>
                    <Button to='solutions' onMouseEnter={onHover}
                    onMouseLeave={onHover}
                    primary={primary ? 1 : 0}
                    dark={dark ? 1 : 0}
                    smooth={true} duration={500} spy={true}
                    exact='true' offset={-80}
                    >
                        Get Started {hover ? <ArrowForward /> : <ArrowRight/>}
                    </Button>
                    </Ripples>
                </HeroBtnWrapper>
            </HeroContent>
        </HeroContainer>
    )
}

export default HeroSection
