import React, {useState, useEffect} from 'react';
import Icon1 from '../../Images/serv.svg'
import Icon2 from '../../Images/serv1.svg'
import Icon3 from '../../Images/serv2.svg'
import Aos from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import { Button } from '../ButtonElements';
import{
        ServicesContainer,
        ServicesH1,
        ServicesCard,
        ServicesH2,
        ServicesIcon,
        ServicesP,
        ServicesWrapper,
        ServicesBtn
} from './ServicesElements';

import { 
    ArrowForward,
    ArrowRight
} from '../HeroSection/HeroElements';


const Services = ({
    primary,
    dark
}) => {
    useEffect(() => {
        Aos.init({duration: 2000 });

    }, []);
    const [hover, setHover] = useState(false)

    const onHover = () => {
        setHover(!hover)
    }
    return (
        <ServicesContainer id='services'>
            <ServicesH1 data-aos="fade-right" data-aos-duration="4000" data-aos-once>Execute your strategy using the Objectives & Key Results software</ServicesH1>
            <ServicesWrapper>
                <ServicesCard data-aos="fade-up" data-aos-duration="2000" data-aos-delay="500"  data-aos-once>
                    <ServicesH2>Accountability Project </ServicesH2>
                    <ServicesIcon src={Icon1}/>
                    <ServicesP>Offers project implementation will be clearer and transparent to minimize errors.</ServicesP>
                    <ServicesBtn>
                    <Button to='solutions' onMouseEnter={onHover}
                            onMouseLeave={onHover}
                            primary={primary ? 1 : 0}
                            dark={dark ? 1 : 0}
                            smooth={true} duration={500} spy={true}
                            exact='true' offset={-80}
                            >
                        Learn More {hover ? <ArrowForward /> : <ArrowRight/>}
                    </Button>
                    </ServicesBtn>
                </ServicesCard>
                <ServicesCard data-aos="fade-up" data-aos-duration="2000" data-aos-delay="700"  data-aos-once>
                     <ServicesH2>Monitoring Best Practices</ServicesH2>
                    <ServicesIcon src={Icon2}/>
                    <ServicesP>monitor the projects based on best practices provided by EFFECTOS</ServicesP>
                    <ServicesBtn>
                    <Button to='solutions' onMouseEnter={onHover}
                            onMouseLeave={onHover}
                            primary={primary ? 1 : 0}
                            dark={dark ? 1 : 0}
                            smooth={true} duration={500} spy={true}
                            exact='true' offset={-80}
                            >
                        Learn More {hover ? <ArrowForward /> : <ArrowRight/>}
                    </Button>
                    </ServicesBtn>
                </ServicesCard>
                <ServicesCard data-aos="fade-up" data-aos-duration="2000" data-aos-delay="900"  data-aos-once>
                    <ServicesH2>Productivity Monitoring</ServicesH2>
                    <ServicesIcon src={Icon3}/>
                    <ServicesP>increase the productivity of a system, by managing work standards properly.</ServicesP>
                    <ServicesBtn>
                    <Button to='solutions' onMouseEnter={onHover}
                            onMouseLeave={onHover}
                            primary={primary ? 1 : 0}
                            dark={dark ? 1 : 0}
                            smooth={true} duration={500} spy={true}
                            exact='true' offset={-80}
                            >
                        Learn More {hover ? <ArrowForward /> : <ArrowRight/>}
                    </Button>
                    </ServicesBtn>
                </ServicesCard>
            </ServicesWrapper>
            
        </ServicesContainer>
    )
}

export default Services
