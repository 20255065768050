import ImageOne from '../../Images/ammar.png'
import ImageTwo from '../../Images/garuda.svg'
import ImageThree from '../../Images/chakra.png'
import ImageFour from '../../Images/pagilaran.png'
import ImageFive from '../../Images/apm.png'
import ImageSix from '../../Images/sinarsosro.png'
// import ImageSeven from '../../Images/ammar.png'

export const gambar1 = {
    id: 'clients',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    imgStart: true,
    img1: ImageOne,
    img2: ImageTwo,
    img3: ImageThree,
    img4: ImageFour,
    img5: ImageFive,
    img6: ImageSix,
    // img7: ImageSeven,
    alt: 'client',
    dark: false,
    primary: false,
    darkText: true
};
