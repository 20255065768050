import React, { useEffect}  from 'react'
import Aos from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import {
    InfoContainer,
    InfoWrapper,
    InfoRow,
    Column1,
    Column2,
    Column3,
    Column4,
    Column5,
    Column6,
    Column7,
    ImgWrap,
    Img,
    ClientsH1,
    ClientsH2
} from './ClientsElements'

const Clients = ({
    lightBg, 
    id, 
    imgStart,  
    img1, 
    img2, 
    img3, 
    img4, 
    img5, 
    img6, 
    img7, 
    alt,
    primary,
    dark,
    dark2
    }) => {
        useEffect(() => {
            Aos.init({duration: 2000 });
    
        }, []);
    return (
        <>
          <InfoContainer lightBg={lightBg} id={id}>
          <ClientsH1 data-aos="fade-down" data-aos-duration="2000" data-aos-once>We have helped our clients to build traceability system for their business</ClientsH1>
          {/* <ClientsH2>We provide project monitoring services throughout Indonesia. During this time more than 50+ companies have become our clients. These include CSO Company, food and beverage company, other large, and medium businesses.</ClientsH2> */}
            <InfoWrapper>
                <InfoRow imgStart={imgStart}>
                    <Column1 data-aos="fade-down" data-aos-duration="2000" data-aos-once data-aos-delay="300">
                        <ImgWrap>
                        <Img src={img1} alt={alt}/>
                        </ImgWrap>
                    </Column1>
                    <Column2 data-aos="fade-down" data-aos-duration="2000" data-aos-once data-aos-delay="500">
                        <ImgWrap>
                        <Img src={img2} alt={alt}/>
                        </ImgWrap>
                    </Column2>
                    <Column3 data-aos="fade-down" data-aos-duration="2000" data-aos-once data-aos-delay="700">
                        <ImgWrap>
                        <Img src={img3} alt={alt}/>
                        </ImgWrap>
                    </Column3>
                    <Column4 data-aos="fade-up" data-aos-duration="2000" data-aos-once data-aos-delay="900">
                        <ImgWrap>
                        <Img src={img4} alt={alt}/>
                        </ImgWrap>
                    </Column4>
                    <Column5 data-aos="fade-up" data-aos-duration="2000" data-aos-once data-aos-delay="1100">
                        <ImgWrap>
                        <Img src={img5} alt={alt}/>
                        </ImgWrap>
                    </Column5>
                    <Column6 data-aos="fade-up" data-aos-duration="2000" data-aos-once data-aos-delay="1300">
                        <ImgWrap>
                        <Img src={img6} alt={alt}/>
                        </ImgWrap>
                    </Column6>
                    {/* <Column7>
                        <ImgWrap>
                        <Img src={img7} alt={alt}/>
                        </ImgWrap>
                    </Column7> */}
                </InfoRow>
            </InfoWrapper>    
          </InfoContainer>  
        </>
    );
};

export default Clients

