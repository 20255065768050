import React, {useState, useEffect} from 'react';
import {FaBars} from 'react-icons/fa';
import {IconContext} from 'react-icons/lib';
import logo from '../../Images/EFFECTOS-BIRU.png'
import {animateScroll as scroll} from 'react-scroll';
import {
    Nav, 
    NavbarContainer, 
    NavLogo, 
    MobileIcon, 
    NavMenu, 
    NavItem, 
    NavLinks,
    NavBtn,
    NavBtnLink,
    // ImgWrap,
    Img
} from './NavbarElements';



const Navbar = ({ toggle }) => {
    const [scrollNav, setScrollNav] = useState(false);

    const changeNav = () => {
        if( window.scrollY >= 80) {
            setScrollNav(true)
        } else{
            setScrollNav(false)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', changeNav)
    }, []);

    const toggleHome= () => {
        scroll.scrollToTop();
    }

    return (
       <>
       <IconContext.Provider value= {{color: '#fff'}}>
            <Nav scrollNav={scrollNav}> 
              <NavbarContainer >
                  <NavLogo to='/' onClick={toggleHome}>
                        <Img src={logo}  alt="logo"/>                 
                  </NavLogo>
                  <MobileIcon onClick={toggle}>
                      <FaBars />
                  </MobileIcon>
                  <NavMenu>
                      <NavItem>
                          <NavLinks to='company'
                          smooth={true} duration={500} spy={true}
                          exact='true' offset={-80}
                          >Company</NavLinks>
                      </NavItem>
                      <NavItem>
                          <NavLinks to='services'   smooth={true} duration={500} spy={true}
                          exact='true' offset={-80} >Services</NavLinks>
                      </NavItem>
                      <NavItem>
                          <NavLinks to='solutions'
                          smooth={true} duration={500} spy={true}
                          exact='true' offset={-80} 
                          >Solutions</NavLinks>
                      </NavItem>

                      <NavItem>
                          <NavLinks to='clients'   smooth={true} duration={500} spy={true}
                          exact='true' offset={-80} >Clients</NavLinks>
                      </NavItem>
                      <NavItem>
                          <NavLinks to='contact'   smooth={true} duration={500} spy={true}
                          exact='true' offset={-80} >Contact</NavLinks>
                      </NavItem>
                      {/* <NavItem>
                          <NavLinks to='signup'>Sign Up</NavLinks>
                      </NavItem> */}
                  </NavMenu>
                  {/* <NavBtn>
                      <NavBtnLink to='/signin'>Sign In</NavBtnLink>
                  </NavBtn> */}
              </NavbarContainer>
            </Nav>
            </IconContext.Provider>
       </>
    );
};

export default Navbar
